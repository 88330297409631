import { createStore } from 'vuex';
export default createStore({
  state: {
    screenHeight: document.documentElement.clientHeight, //屏幕高度
    userInfo: {}, //用户信息
    refreshShow: true, // 是否定时刷新页面，true刷新，false禁止刷新
  },
  getters: {},
  mutations: {
    upateScreenHeight(state, data) {
      state.screenHeight = data;
    },
    updateUserInfo(state, data) {
      state.userInfo = data;
    },
    updateRefreshShow(state, data) {
      state.refreshShow = data;
    },
    updateTimerShow(state, data) {
      state.timerShow = data;
    },
  },
  actions: {},
  modules: {},
});
