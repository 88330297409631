<template>
  <div style="width:100%;height:100%">
    <router-view></router-view>
    <el-row class="bottomJump"
            :span="24"
            v-if="$route.path!=='/'">
      <el-col :span="4">
        <div class="jump"
             @click="jumpClick('首页')">首页</div>
      </el-col>
      <el-col :span="4">
        <div class="jump"
             @click="jumpClick('东车间')">东车间</div>
      </el-col>
      <el-col :span="4">
        <div class="jump"
             @click="jumpClick('西车间')">西车间</div>
      </el-col>
      <el-col :span="4">
        <div class="jump"
             @click="jumpClick('走芯机')">走芯机</div>
      </el-col>
      <el-col :span="4">
        <div class="jump"
             @click="jumpClick('其他')">其他</div>
      </el-col>
      <el-col :span="4">
        <div class="jump"
             @click="jumpClick('重登')">重登</div>
      </el-col>
      <el-col :span="1">
        <div style="color:#303030;width:100%;height:1rem"
             @click="textShowChange(textShow)"
             @touchstart.prevent="textShowChange(textShow)">
          <div class="poPup"
               v-if="textShow">{{widHeight}}</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
let pathJumpFleg = window.GlobalConfig.pathJumpFleg
let timerSecond = window.GlobalConfig.timerSecond // 刷新时间间隔
let timerShow = window.GlobalConfig.timerShow // 是否刷新
let rem = window.GlobalConfig.rem // 是否刷新
export default {
  name: "app",
  data() {
    return {
      setInterval: null,
      widHeight: '',
      textShow: false,
      fleg: true,
      orientationData: null
    }
  },
  components: {},
  computed: {
    refreshShow() {
      return this.$store.state.refreshShow
    }
  },
  watch: {
    refreshShow: {
      handler(res) {
        console.log(res);
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    let that = this
    if (!this.isMobile) {
      window.addEventListener("resize", function () {
        location.reload();
        that.widHeight = '窗口大小：' + document.documentElement.clientWidth + ',' + document.documentElement.clientHeight + ',' + window.screen.width + ',' + window.screen.height
        that.$store.commit('upateScreenHeight', document.documentElement.clientHeight)
      });
    } else {
      window.addEventListener("resize", function () {
        that.widHeight = '窗口大小：' + document.documentElement.clientWidth + ',' + document.documentElement.clientHeight + ',' + window.screen.width + ',' + window.screen.height
        that.$store.commit('upateScreenHeight', document.documentElement.clientHeight)
      });
    }
    this.widHeight = '窗口大小：' + document.documentElement.clientWidth + ',' + document.documentElement.clientHeight + ',' + window.screen.width + ',' + window.screen.height
    window.clearInterval(this.setInterval)
    this.setIntervalTimer = setInterval(() => {
      if (timerShow) {
        that.$store.commit('updateTimerShow', Object({ fleg: true }))
      }
    }, timerSecond)
    if (!pathJumpFleg) {
      return false
    }
    this.setInterval = setInterval(() => {
      if (this.refreshShow) {
        switch (this.$route.path) {
          case '/eastWorkshop':
            this.$router.push('/westWorkshop')
            break;
          case '/westWorkshop':
            this.$router.push('/equipmentStatus')
            break;
          case '/equipmentStatus':
            this.$router.push('/eastWorkxlshop')
            break;
          case '/eastWorkxlshop':
            this.$router.push('/westWorkxlshop')
            break;
          case '/westWorkxlshop':
            this.$router.push('/home')
            break;
          case '/home':
            this.$router.push('/westWorkshop')
            break;
        }
      } else {
        console.log('禁止刷新页面');
      }
    }, 60000);
  },
  methods: {
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    textShowChange(fleg) {
      this.textShow = !this.textShow
      this.widHeight = '窗口大小：' + document.documentElement.clientWidth + ',' + document.documentElement.clientHeight + ',' + window.screen.width + ',' + window.screen.height
    },
    // 跳转事件
    jumpClick(type) {
      switch (type) {
        case '首页':
          this.$router.push('/home')
          break;
        case '东车间':
          this.$router.push('/eastWorkxlshop')
          break;
        case '西车间':
          this.$router.push('/westWorkxlshop')
          break;
        case '走芯机':
        this.$router.push('/coreWalkingMachine')
          break;
        case '其他':
          this.$router.push('/equipmentStatus')
          break;
        case '重登':
          this.$router.push('/')
          break;
        default:
          this.$router.push('/')
          break;
      }
    }
  },
};
</script>
<style lang="less">
@import "./assets/style/common.css";
</style>
<style>
html {
  font-size: 16px;
}
@media only screen and (min-width: 1200px) {
  html {
    font-size: 10px;
  }
}
@media only screen and (min-width: 1920px) {
  html {
    font-size: 16px;
  }
}
@media only screen and (min-width: 3600px) {
  html {
    font-size: 30px;
  }
}
body {
  background: url("./assets/images/icon_bj.png");
  background-size: cover;
  margin: 0;
  padding: 0;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#main {
  margin: auto;
}
</style>
<style lang="less">
.bottomJump {
  position: fixed !important;
  width: 100%;
  height: 1.5rem;
  bottom: 0;
  cursor: pointer;
  background: #303030;
  z-index: 99;
  .jump {
    position: relative;
    height: 1.5rem;
    line-height: 1.5rem;
    -webkit-text-size-adjust: none;
    font-size: 1rem;
    text-align: center;
    color: #fff;
  }
  .poPup {
    position: absolute;
    color: #fff;
    bottom: 2rem;
    border: 0.1rem solid #fff;
    width: 20rem;
    height: 2rem;
    line-height: 2rem;
    right: 0;
    background: #333;
  }
}
</style>