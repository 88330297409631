import { createRouter, createWebHashHistory } from "vue-router";
import { getCookie,removeCookie } from '../api/cookies'

const routes = [
  {
    path: "/",
    name: "loginPage",
    component: () => import('../views/LoginPage.vue')
  },
  {
    path: "/home",
    name: "home",
    component: () => import('../views/homeView.vue')
  },
  {
    path: "/westWorkshop",
    name: "westWorkshop",
    component: () => import('../views/westWorkshop.vue')
  },
  {
    path: "/eastWorkshop",
    name: "eastWorkshop",
    component: () => import('../views/eastWorkshop.vue')
  },
  {
    path: "/equipmentStatus",
    name: "equipmentStatus",
    component: () => import('../views/equipmentStatus.vue')
  },
  {
    path: "/westWorkxlshop",
    name: "westWorkxlshop",
    component: () => import('../views/westWorkxlshop.vue')
  },
  {
    path: "/eastWorkxlshop",
    name: "eastWorkxlshop",
    component: () => import('../views/eastWorkxlshop.vue')
  },
  {
    path: "/coreWalkingMachine",
    name: "coreWalkingMachine",
    component: () => import('../views/coreWalkingMachine.vue')
  },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  if(to.name==='loginPage'){
    if(getCookie('token')){
      removeCookie('token')
    }
    next()
  }else{
    if(getCookie('token')) {
      if (to.meta.title) {
        document.title = to.meta.title
      }
      next()
    }else{
      next({
        path: '/', //无登录状态,先跳转到登录页面
        query: {} 
      })
    }
  }
  
  
})
export default router;
