import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as echarts from 'echarts';
import numberGrow from './components/numberGrow.vue';
const app = createApp(App);
app.config.globalProperties.$echarts = echarts;
app.use(numberGrow);
app.use(store);
app.use(router);
app.use(VueAxios, axios);
app.use(ElementPlus);
app.mount('#app');
